@tailwind base;
@tailwind components;
@font-face {
    font-family: IRANSansXFaNum;
    font-style: normal;
    font-weight: 100;
    src: url('assets/fonts/Woff/IRANSansXFaNum-Thin.woff') format('woff'),
    url('assets/fonts/Woff2/IRANSansXFaNum-Thin.woff2') format('woff2');
}

@font-face {
    font-family: IRANSansXFaNum;
    font-style: normal;
    font-weight: 200;
    src: url('assets/fonts/Woff/IRANSansXFaNum-UltraLight.woff') format('woff'),
    url('assets/fonts/Woff2/IRANSansXFaNum-UltraLight.woff2') format('woff2');
}

@font-face {
    font-family: IRANSansXFaNum;
    font-style: normal;
    font-weight: 300;
    src: url('assets/fonts/Woff/IRANSansXFaNum-Light.woff') format('woff'),
    url('assets/fonts/Woff2/IRANSansXFaNum-Light.woff2') format('woff2');
}

@font-face {
    font-family: IRANSansXFaNum;
    font-style: normal;
    font-weight: 500;
    src: url('assets/fonts/Woff/IRANSansXFaNum-Medium.woff') format('woff'),
    url('assets/fonts/Woff2/IRANSansXFaNum-Medium.woff2') format('woff2');
}

@font-face {
    font-family: IRANSansXFaNum;
    font-style: normal;
    font-weight: 600;
    src: url('assets/fonts/Woff/IRANSansXFaNum-DemiBold.woff') format('woff'),
    url('assets/fonts/Woff2/IRANSansXFaNum-DemiBold.woff2') format('woff2');
}

@font-face {
    font-family: IRANSansXFaNum;
    font-style: normal;
    font-weight: 800;
    src: url('assets/fonts/Woff/IRANSansXFaNum-ExtraBold.woff') format('woff'),
    url('assets/fonts/Woff2/IRANSansXFaNum-ExtraBold.woff2') format('woff2');
}

@font-face {
    font-family: IRANSansXFaNum;
    font-style: normal;
    font-weight: 900;
    src: url('assets/fonts/Woff/IRANSansXFaNum-Black.woff') format('woff'),
    url('assets/fonts/Woff2/IRANSansXFaNum-Black.woff2') format('woff2');
}

@font-face {
    font-family: IRANSansXFaNum;
    font-style: normal;
    font-weight: bold;
    src: url('assets/fonts/Woff/IRANSansXFaNum-Bold.woff') format('woff'),
    url('assets/fonts/Woff2/IRANSansXFaNum-Bold.woff2') format('woff2');
}

@font-face {
    font-family: IRANSansXFaNum;
    font-style: normal;
    font-weight: normal;
    src: url('assets/fonts/Woff/IRANSansXFaNum-Regular.woff') format('woff'),
    url('assets/fonts/Woff2/IRANSansXFaNum-Regular.woff2') format('woff2');
}

.fontEn {
    font-family: "Arial", serif ;
}

html {
    @apply font-body
}

@tailwind utilities;

@layer components {
    .btn-red {
        @apply bg-red-500 dark:bg-red-800 hover:bg-red-400 dark:hover:bg-red-700 flex flex-row justify-center items-center gap-1 px-2 py-1 rounded  border-none;
    }
    .btn-green {
        @apply bg-green-500 dark:bg-green-800 hover:bg-green-400 dark:hover:bg-green-700 flex flex-row justify-center items-center gap-1 px-2 py-1 rounded  border-none;
    }
    .btn-blue {
        @apply bg-blue-500 dark:bg-blue-800 hover:bg-blue-400 dark:hover:bg-blue-700 flex flex-row justify-center items-center gap-1 px-2 py-1 rounded  border-none;
    }
}
